.container {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.slider {
  height: 150px;
}
