.interval-hand-area {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
}

.interval-hand {
  margin-right: 20px;
}

@media (width <= 600px) {
  .interval-area {
    transform: scale(0.7);
  }
}

@media (width <= 400px) {
  .interval-area {
    transform: scale(0.7);
  }
}
