.hand-area {
  display: flex;
  flex-flow: row wrap-reverse;
  align-items: flex-end;
  justify-content: right;
  gap: 10px 8px;
  width: 100%;
}

.my-hand-area {
  margin-bottom: 20%;
}

.opponent-hand-area {
  justify-content: left;
}

.hand-wrap {
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex-grow: 1;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  min-height: 40px;
}

.action-buttons>* {
  visibility: visible;
  margin-right: 10px;
}

.action-buttons>*:last-child {
  margin-right: 0;
}

.discard-container {
  display: block;
  width: 100%;
  max-width: 300px;
  visibility: visible;
}

.center-panel-container {
  display: flex;
  align-items: center;
}

@media (width <=600px) {
  .hand-area {
    transform: scale(0.8);
  }

  .discard-container {
    transform: scale(0.8);
  }

  .action-buttons {
    margin-right: 32px;
    min-height: 32px;
  }
}

@media (width <=400px) {
  .hand-area {
    transform: scale(0.65);
  }

  .discard-container {
    transform: scale(0.65);
  }
}
