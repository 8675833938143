.opponent-meld {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.opponent-meld-group {
  display: flex;
  align-items: flex-start;
}

.opponent-meld-vertical-group {
  display: flex;
  flex-direction: column;
}

.opponent-meld-vertical-group .tile:last-child {
  margin-top: -15px;
  margin-bottom: 0;
}
