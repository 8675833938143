.meld-group {
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
}

.meld-vertical-group {
  display: flex;
  flex-direction: column;
}

.meld-vertical-group .tile:last-child {
  margin-top: -15px;
  margin-bottom: 0;
}
