.discard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.opponent-discard {
  justify-content: left;
  transform: rotate(180deg);
}
