.center-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.center-panel-flexible {
  display: flex;
  justify-content: space-around;
  padding: 8px 16px;
  flex-direction: column;
  gap: 16px;

  @media (width <= 500px) {
    gap: 8px;
    flex-direction: row;
  }
}

.center-panel-indicator {
  animation: center-panel-indicator-blinking 1.5s infinite;
}

@keyframes center-panel-indicator-blinking {
  0% {
    background-color: rgb(255 165 0 / 100%);
  }

  50% {
    background-color: rgb(255 165 0 / 0%);
  }

  100% {
    background-color: rgb(255 165 0 / 100%);
  }
}

@media (width <= 600px) {
  .center-panel {
    transform: scale(0.8);
  }
}

@media (width <= 400px) {
  .center-panel {
    transform: scale(0.65);
  }
}
