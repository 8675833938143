.reload-button {
  position: absolute;
  left: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 50%;
}

.reload-button:hover {
  background-color: #f0f0f0;
}
