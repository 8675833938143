.tile {
  width: 33px;
  height: 59px;
}

.tile-hover-anim:hover {
  filter: contrast(150%);
  transform: translateY(-10%);
}

.tile-horizontal {
  width: 44px;
  height: 49px;
}

.tile-immediately-after-draw {
  margin-left: 20px;
}

.tile-darken {
  filter: brightness(50%);
}
